@use "../settings/vars" as v;
@use "../settings/mixins" as m;


.kv-text-item {
  display: block;
  overflow: hidden;
}
.kv-text-item-inner {
  display: block;
  opacity: 0;
}

.lower-kv-lead {
  opacity: 0;
}

// stepIn
.stepIn {
  animation-name: slideTextY100;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}
@keyframes slideTextY100 {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

// slideIn
.slideIn {
  animation-name: slideTextY20;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
}
@keyframes slideTextY20 {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

// fadeInUp
.fadeInUp {
  opacity: 0;
}

// zoomOut
.zoomOut {
  animation-name: zoomOut;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
}
@keyframes zoomOut {
  from {
    transform: scale(1.05);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}