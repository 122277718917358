// colors
$white: #fff;
$base: #333129;
$base-light: #5C5849;
$base-xlight: #9A9376;
$base-xxlight: #DBD4BE;
$primary: #FFBE41;
$secondary: #2C477A;
$bg: #F0EBD8;
$bg-light: #F5F3EB;
$border: #E5E3DC;
$border-dark: #CCC7B4;
$brand-main: #E6E2CF;

// font-family
@mixin zenkaku {
  font-family: "Zen Kaku Gothic New", sans-serif;
}
@mixin mincho {
  font-family: "Shippori Mincho", serif;
  font-weight: 400;
}
@mixin noto {
  font-family: "Noto Serif Display", serif;
  font-weight: 400;
}
@mixin gantari {
  font-family: "Gantari", sans-serif;
}

