@use "../settings/vars" as v;
@use "../settings/mixins" as m;

// inner
.inner-s,
.inner-m,
.inner-l {
	margin: 0 auto;
	padding: 0 20px;
	box-sizing: content-box;
	&.p0 {
		padding: 0;
	}
}
.inner-s {
	max-width: 840px;
}
.inner-m {
	max-width: 1060px;
}
.inner-l {
	max-width: 1280px;
}
.max840 {
	margin: 0 auto;
	padding: 0 20px;
	box-sizing: content-box;
}
.max840 {
	max-width: 840px;
}

// fixed
.fixed {
	position: fixed;
	right: 0;
	left: 0;
}

// title
.section-title {
	color: v.$base-xlight;
	@include v.mincho;
	font-size: 36px;
	@include m.mq(max599) {
		font-size: 28px;
	}
}
.content-title {
	@include v.mincho;
	font-size: 28px;
	@include m.mq(max599) {
		font-size: 24px;
	}
}
.group-title {
	color: v.$base-light;
	font-weight: 700;
	font-size: 18px;
	letter-spacing: 0.04em;
	@include m.mq(max599) {
		font-size: 18px;
	}
}

// text
.xxl {
	font-size: 24px;
	line-height: 2;
	@include m.mq(max599) {
		font-size: 16px;
		line-height: 2.25;
	}
}
.xl {
	font-size: 20px;
	line-height: 2;
	@include m.mq(max599) {
		font-size: 16px;
		line-height: 2.25;
	}
}
.lg {
	font-size: 18px;
	line-height: 2;
	@include m.mq(max599) {
		font-size: 16px;
	}
}
.md {
	font-size: 16px;
	line-height: 2;
}

.la-en {
	.xxl {
		font-size: 20px;
	}
	.xl {

	}
}

// 改行
br {
  &.sp-only {
    display: none;
    @include m.mq(max599) {
      display: block;
    }
  }
  &.sp-tab-only {
    display: none;
    @include m.mq(max1023) {
      display: block;
    }
  }
}

// list
.list,
.number-list {
	display: flex;
	flex-direction: column;
	gap: 12px;
	font-size: 15px;
	line-height: 1.75;
	&.vertical {
		flex-direction: row;
		align-items: baseline;
		flex-wrap: wrap;
		gap: 24px;
	}
	a {
		color: v.$secondary;
		text-decoration: underline;
		word-break: break-all;
		@include m.hover() {
			text-decoration: none;
		}
	}
}
.number-list {
	margin-left: 1.4em;
	li {
		text-indent: -1.4em;
	}
}
.number {
	padding-right: 9px;
	color: v.$primary;
	@include v.noto;
	font-weight: 700;
	letter-spacing: 0.04em;
}
.border-list {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin: 10px 0 0 18px;
	font-size: 14px;
	li {
		position: relative;
		padding-left: 1.4rem;
		&::before {
			content: '';
			position: absolute;
			top: 12px;
			left: 0;
			width: 12px;
			height: 1px;
			background-color: v.$border;
		}
	}
}
.pdf-list {
	display: flex;
	gap: 16px;
	margin-top: 36px;
	@include m.mq(max599) {
		flex-direction: column;
		align-items: flex-start;
	}
}
.pdf-item {
	display: flex;
	align-items: center;
	gap: 8px;
	position: relative;
	padding: 15px 37px 15px 15px;
	background-color: v.$bg-light;
	transition: 0.3s;
	.pdf {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 42px;
		height: 20px;
		background-color: v.$base-xlight;
		color: v.$white;
		@include v.gantari;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: 0.08em;
	}
	.text {
		letter-spacing: 0.04em;
	}
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 20px;
		width: 12px;
		height: 12px;
		background-image: url(../images/icon_external_beige.svg);
		background-position: center 0;
		background-size: contain;
		background-repeat: no-repeat;
	}
	@include m.hover() {
		background-color: v.$base-xxlight;
		transition: 0.3s;
	}
}

// diamond
.diamond {
	display: inline-block;
	position: relative;
	padding-left: 1.4rem;
	&::before {
		content: '';
		position: absolute;
		top: 0.65em;
		left: 0;
		width: 6px;
		height: 6px;
		transform: rotate(45deg);
		background-color: v.$primary;
	}
}

// など
.etc {
	margin-left: 17px;
	font-size: 14px;
}

// text-align
.center {
	text-align: center;
}

// margin
.mb0 {
	margin-bottom: 0;
}

// main
main {
	padding-top: 112px;
	@include m.mq(max599) {
		padding-top: 80px;
	}
}

// header
.header {
	position: fixed;
	top: 0;
	z-index: 100;
	width: 100%;
	padding: 37px 0;
	background-color: v.$bg;
	transition: 0.5s;
	@include m.mq(max599) {
		height: 80px;
		padding: 25px 0;
	}
}
.scroll-header {
	padding: 25px 0;
	transition: 0.5s;
}
.header-inner {
	display: flex;
	justify-content: space-between;
	align-items: center;

}
.header-logo {
	position: relative;
	z-index: 200;
	transition: 0.3s;
	@include m.hover() {
		opacity: 0.7;
		transition: 0.3s;
	}
	img {
		@include m.mq(max599) {
			width: 280px;
		}
		@include m.mq(max374) {
			width: 70vw;
		}
	}
}
.header-nav {
	display: flex;
	gap: 56px;
	@include v.gantari;
	font-weight: 700;
	@include m.mq(max1023) {
		opacity: 0;
		visibility: hidden;
		transition: 0.3s;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 48px;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		z-index: 100;
		width: 100%;
		background-color: v.$bg;
	}
	&.active {
		opacity: 1;
		visibility: visible;
		transition: 0.3s;
	}
}
.header-nav-list {
	display: flex;
	align-items: center;
	gap: 40px;
	font-size: 18px;
	letter-spacing: 0.02em;
	@include m.mq(max1023) {
		flex-direction: column;
		align-items: flex-start;
		gap: 24px;
	}
	li {
		position: relative;
		&::after {
			content: '';
			opacity: 0;
			visibility: hidden;
			position: absolute;
			bottom: -10px;
			left: 50%;
			width: 6px;
			height: 6px;
			transform: translateX(-50%) rotate(45deg);
			background-color: v.$primary;
			transition: 0.3s;
			@include m.mq(max1023) {
				left: -16px;
				bottom: 50%;
				transform: translateY(50%) rotate(45deg);
			}
		}
		&.active {
			&::after {
				opacity: 1;
				visibility: visible;
			}
		}
		@include m.hover() {
			&::after {
				opacity: 1;
				visibility: visible;
				transition: 0.3s;
			}
		}
	}
}
.header-menu-btn {
	display: none;
	@include m.mq(max1023) {
		display: block;
		position: fixed;
		bottom: 12px;
		right: 12px;
		z-index: 200;
		width: 56px;
		height: 56px;
		box-shadow: 0 0 40px 0 color-mix(in srgb, v.$base 8%, transparent);
		border-radius: 50%;
		background-color: v.$white;
		transition: 0.9s;
		cursor: pointer;
	}
}
.header-menu-btn-inner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 24px;
	height: 16px;
	span {
		position: absolute;
		left: 0;
		height: 2px;
		background-color: v.$base-light;
		transition: 0.3s;
		&:nth-child(1) {
			top: 0;
			width: 100%;
		}
		&:nth-child(2) {
			top: 50%;
			transform: translateY(-50%);
			width: 16px;
		}
		&:nth-child(3) {
			bottom: 0;
			width: 100%;
		}
	}
	&.active {
		span {
			position: absolute;
			left: 0;
			height: 2px;
			background-color: v.$base-light;
			transition: 0.3s;
			&:nth-child(1) {
				top: 0;
				width: 100%;
				transform: translateY(7px) rotate(45deg) ;
			}
			&:nth-child(2) {
				opacity: 0;
			}
			&:nth-child(3) {
				bottom: 0;
				width: 100%;
				transform: translateY(-7px) rotate(-45deg);
			}
		}
	}
}

// footer
.footer {
	margin: 160px 0 60px;
	@include m.mq(max599) {
		margin: 80px 0 48px;
	}
}
.footer-inner {
	display: flex;
	justify-content: space-between;
	gap: 36px;
	@include v.gantari;
	letter-spacing: 0.02em;
	@include m.mq(max1023) {
		flex-direction: column;
	}
}
.footer-logo {
	@include m.mq(max599) {
		width: 228px;
	}
}
.footer-info {
	margin-top: 30px;
	font-size: 14px;
}
.footer-nav {
	display: flex;
	justify-content: flex-end;
	gap: 24px;
	font-weight: 700;
	font-size: 16px;
	@include m.mq(max1023) {
		justify-content: flex-start;
	}
	a {
		transition: 0.3s;
		@include m.hover() {
			opacity: 0.7;
			transition: 0.3s;
		}
	}
}
.footer-copyright {
	display: block;
	margin-top: 60px;
	font-size: 14px;
	@include m.mq(max1023) {
		margin-top: 32px;
	}
	span {
		display: inline-block;
	}
}
