@use "../settings/vars" as v;
@use "../settings/mixins" as m;

// ボタン
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  min-height: 72px;
  overflow: hidden;
  border-radius: 36px;
  background-color: v.$base;
  color: v.$white;
  @include v.gantari;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.04em;
  line-height: 1.35;
  span {
    padding-right: 10px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 0;
    height: 100%;
    background-color: v.$base-xlight;
    transition: ease-out 0.2s;
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    width: 48px;
    height: 48px;
    background-position: center 0;
    background-size: contain;
    background-repeat: no-repeat;
  }
  @include m.hover() {
    &::before {
      width: 100%;
    }
  }

  &.more-btn {
    max-width: 260px;
    margin-top: 36px;
    &::after {
      background-image: url(../images/icon_arrow.svg);
    }
  }
  &.contact-btn {
    max-width: 420px;
    margin-top: 64px;
    @include m.mq(max599) {
      margin-top: 48px;
      max-width: 300px;
    }
    span {
      padding-top: 3px;
    }
    &::after {
      background-image: url(../images/icon_external_black.svg);
    }
  }
  &.center {
    margin-right: auto;
    margin-left: auto;
  }
  &.sp-tab-center {
    @include m.mq(max1023) {
      margin-right: auto;
      margin-left: auto;
    }
  }
}

// 翻訳ボタン
.la-switch-btns {
	display: flex;
	gap: 2px;
	font-size: 14px;
	letter-spacing: 0.08em;
	@include m.mq(max1023) {
		gap: 6px;
	}
}
.la-switch-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 25px;
  border-radius: 21px;
  transition: 0.3s;
  &.active {
    pointer-events: none;
    background-color: v.$white;
  }
  @include m.hover() {
    background-color: v.$white;
    transition: 0.3s;
  }
}

// クッキー
.cookie-consent {
  position: fixed;
  bottom: -100%;
  z-index: 500;
  width: 100%;
  padding: 0 20px;
  transition: all 1s;
  &.is-show {
    bottom: 0;
    transition: all 1s;
  }
}
.cookie-consent-inner {
  display: flex;
  gap: 20px;
  max-width: 1376px;
  margin: 0 auto 20px;
  padding: 20px 32px;
  border-radius: 12px;
  box-shadow: 0 0 40px 0 color-mix(in srgb, v.$base 8%, transparent);
  background-color: v.$white;
  @include m.mq(max599) {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 16px;
  }
}
.cookie-consent-text {
  font-size: 14px;
  line-height: 2;
  a {
    color: v.$secondary;
    font-weight: 700;
    text-decoration: underline;
    transition: 0.3s;
    &:hover {
      text-decoration: none;
      transition: 0.3s;
    }
  }
}
.cookie-btns {
  display: flex;
  align-items: center;
  gap: 16px;
  letter-spacing: 0.04em;
  white-space: nowrap;
}
.cookie-btn {
  cursor: pointer;
}
.cookie-agree {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 56px;
  border-radius: 40px;
  background-color: v.$secondary;
  color: v.$white;
  font-weight: 700;
  letter-spacing: 0.04em;
  transition: 0.3s;
  @include m.hover() {
    background-color: v.$base;
    transition: 0.3s;
  }
}
.cookie-disagree {
  color: v.$base-xlight;
  font-size: 14px;
  transition: 0.3s;
  @include m.hover() {
    color: v.$base;
    transition: 0.3s;
  }
}
