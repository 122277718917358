@use "../settings/vars" as v;
@use "../settings/mixins" as m;

.en {
  .btn.more-btn {
    max-width: 300px;
  }
  .kv-text,
  .section-title,
  .content-title,
  .group-title,
  .policy-title {
    @include v.noto;
  }
  .policy-table {
    &.w1023 {
      th:last-child {
        .br-last {
          @include m.mq(max1023) {
            display: none;
          }
        }
      }
    }
    &.w767 {
      th:last-child {
        .br-last {
          display: none;
          @include m.mq(max1023) {
            display: block;
          }
          @include m.mq(max767) {
            display: none;
          }
        }
      }
    }
  }
}