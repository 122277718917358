@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

textarea {
  resize: none;
}

input[type=submit],
input[type=button],
label,
button,
select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

* {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: none;
  overflow-y: scroll;
  scroll-snap-type: y proximity;
  scroll-padding-top: 140px;
}

body {
  font-family: "Zen Kaku Gothic New", sans-serif;
  background-color: #F0EBD8;
  color: #333129;
  line-height: 1.5;
  font-feature-settings: "palt";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body.ja {
  text-align: justify;
}

a {
  color: #333129;
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

ol {
  padding-left: 20px;
  list-style-type: decimal;
}

strong {
  font-weight: bold;
}

sup, sub {
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  _vertical-align: bottom;
  position: relative;
  font-size: 1rem;
}

sup {
  bottom: 1ex;
}

sub {
  top: 0.5ex;
}

/* IE表示用のCSS　*/
body.ie {
  font-family: YakuHanJP_Narrow, Meiryo, sans-serif;
}

.is-fixed {
  width: 100%;
  height: 100%;
  position: fixed;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  min-height: 72px;
  overflow: hidden;
  border-radius: 36px;
  background-color: #333129;
  color: #fff;
  font-family: "Gantari", sans-serif;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.04em;
  line-height: 1.35;
}
.btn span {
  padding-right: 10px;
}
.btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 0;
  height: 100%;
  background-color: #9A9376;
  transition: ease-out 0.2s;
}
.btn::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  width: 48px;
  height: 48px;
  background-position: center 0;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (hover: hover) and (pointer: fine) {
  .btn:hover::before {
    width: 100%;
  }
}
.btn.more-btn {
  max-width: 260px;
  margin-top: 36px;
}
.btn.more-btn::after {
  background-image: url(../images/icon_arrow.svg);
}
.btn.contact-btn {
  max-width: 420px;
  margin-top: 64px;
}
@media screen and (max-width: 599px) {
  .btn.contact-btn {
    margin-top: 48px;
    max-width: 300px;
  }
}
.btn.contact-btn span {
  padding-top: 3px;
}
.btn.contact-btn::after {
  background-image: url(../images/icon_external_black.svg);
}
.btn.center {
  margin-right: auto;
  margin-left: auto;
}
@media screen and (max-width: 1023px) {
  .btn.sp-tab-center {
    margin-right: auto;
    margin-left: auto;
  }
}

.la-switch-btns {
  display: flex;
  gap: 2px;
  font-size: 14px;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 1023px) {
  .la-switch-btns {
    gap: 6px;
  }
}

.la-switch-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 25px;
  border-radius: 21px;
  transition: 0.3s;
}
.la-switch-btn.active {
  pointer-events: none;
  background-color: #fff;
}
@media (hover: hover) and (pointer: fine) {
  .la-switch-btn:hover {
    background-color: #fff;
    transition: 0.3s;
  }
}

.cookie-consent {
  position: fixed;
  bottom: -100%;
  z-index: 500;
  width: 100%;
  padding: 0 20px;
  transition: all 1s;
}
.cookie-consent.is-show {
  bottom: 0;
  transition: all 1s;
}

.cookie-consent-inner {
  display: flex;
  gap: 20px;
  max-width: 1376px;
  margin: 0 auto 20px;
  padding: 20px 32px;
  border-radius: 12px;
  box-shadow: 0 0 40px 0 color-mix(in srgb, #333129 8%, transparent);
  background-color: #fff;
}
@media screen and (max-width: 599px) {
  .cookie-consent-inner {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 16px;
  }
}

.cookie-consent-text {
  font-size: 14px;
  line-height: 2;
}
.cookie-consent-text a {
  color: #2C477A;
  font-weight: 700;
  text-decoration: underline;
  transition: 0.3s;
}
.cookie-consent-text a:hover {
  text-decoration: none;
  transition: 0.3s;
}

.cookie-btns {
  display: flex;
  align-items: center;
  gap: 16px;
  letter-spacing: 0.04em;
  white-space: nowrap;
}

.cookie-btn {
  cursor: pointer;
}

.cookie-agree {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 56px;
  border-radius: 40px;
  background-color: #2C477A;
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.04em;
  transition: 0.3s;
}
@media (hover: hover) and (pointer: fine) {
  .cookie-agree:hover {
    background-color: #333129;
    transition: 0.3s;
  }
}

.cookie-disagree {
  color: #9A9376;
  font-size: 14px;
  transition: 0.3s;
}
@media (hover: hover) and (pointer: fine) {
  .cookie-disagree:hover {
    color: #333129;
    transition: 0.3s;
  }
}

.inner-s,
.inner-m,
.inner-l {
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: content-box;
}
.inner-s.p0,
.inner-m.p0,
.inner-l.p0 {
  padding: 0;
}

.inner-s {
  max-width: 840px;
}

.inner-m {
  max-width: 1060px;
}

.inner-l {
  max-width: 1280px;
}

.max840 {
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: content-box;
}

.max840 {
  max-width: 840px;
}

.fixed {
  position: fixed;
  right: 0;
  left: 0;
}

.section-title {
  color: #9A9376;
  font-family: "Shippori Mincho", serif;
  font-weight: 400;
  font-size: 36px;
}
@media screen and (max-width: 599px) {
  .section-title {
    font-size: 28px;
  }
}

.content-title {
  font-family: "Shippori Mincho", serif;
  font-weight: 400;
  font-size: 28px;
}
@media screen and (max-width: 599px) {
  .content-title {
    font-size: 24px;
  }
}

.group-title {
  color: #5C5849;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.04em;
}
@media screen and (max-width: 599px) {
  .group-title {
    font-size: 18px;
  }
}

.xxl {
  font-size: 24px;
  line-height: 2;
}
@media screen and (max-width: 599px) {
  .xxl {
    font-size: 16px;
    line-height: 2.25;
  }
}

.xl {
  font-size: 20px;
  line-height: 2;
}
@media screen and (max-width: 599px) {
  .xl {
    font-size: 16px;
    line-height: 2.25;
  }
}

.lg {
  font-size: 18px;
  line-height: 2;
}
@media screen and (max-width: 599px) {
  .lg {
    font-size: 16px;
  }
}

.md {
  font-size: 16px;
  line-height: 2;
}

.la-en .xxl {
  font-size: 20px;
}
br.sp-only {
  display: none;
}
@media screen and (max-width: 599px) {
  br.sp-only {
    display: block;
  }
}
br.sp-tab-only {
  display: none;
}
@media screen and (max-width: 1023px) {
  br.sp-tab-only {
    display: block;
  }
}

.list,
.number-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 15px;
  line-height: 1.75;
}
.list.vertical,
.number-list.vertical {
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 24px;
}
.list a,
.number-list a {
  color: #2C477A;
  text-decoration: underline;
  word-break: break-all;
}
@media (hover: hover) and (pointer: fine) {
  .list a:hover,
.number-list a:hover {
    text-decoration: none;
  }
}

.number-list {
  margin-left: 1.4em;
}
.number-list li {
  text-indent: -1.4em;
}

.number {
  padding-right: 9px;
  color: #FFBE41;
  font-family: "Noto Serif Display", serif;
  font-weight: 400;
  font-weight: 700;
  letter-spacing: 0.04em;
}

.border-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px 0 0 18px;
  font-size: 14px;
}
.border-list li {
  position: relative;
  padding-left: 1.4rem;
}
.border-list li::before {
  content: "";
  position: absolute;
  top: 12px;
  left: 0;
  width: 12px;
  height: 1px;
  background-color: #E5E3DC;
}

.pdf-list {
  display: flex;
  gap: 16px;
  margin-top: 36px;
}
@media screen and (max-width: 599px) {
  .pdf-list {
    flex-direction: column;
    align-items: flex-start;
  }
}

.pdf-item {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  padding: 15px 37px 15px 15px;
  background-color: #F5F3EB;
  transition: 0.3s;
}
.pdf-item .pdf {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 20px;
  background-color: #9A9376;
  color: #fff;
  font-family: "Gantari", sans-serif;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.08em;
}
.pdf-item .text {
  letter-spacing: 0.04em;
}
.pdf-item::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  width: 12px;
  height: 12px;
  background-image: url(../images/icon_external_beige.svg);
  background-position: center 0;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (hover: hover) and (pointer: fine) {
  .pdf-item:hover {
    background-color: #DBD4BE;
    transition: 0.3s;
  }
}

.diamond {
  display: inline-block;
  position: relative;
  padding-left: 1.4rem;
}
.diamond::before {
  content: "";
  position: absolute;
  top: 0.65em;
  left: 0;
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
  background-color: #FFBE41;
}

.etc {
  margin-left: 17px;
  font-size: 14px;
}

.center {
  text-align: center;
}

.mb0 {
  margin-bottom: 0;
}

main {
  padding-top: 112px;
}
@media screen and (max-width: 599px) {
  main {
    padding-top: 80px;
  }
}

.header {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  padding: 37px 0;
  background-color: #F0EBD8;
  transition: 0.5s;
}
@media screen and (max-width: 599px) {
  .header {
    height: 80px;
    padding: 25px 0;
  }
}

.scroll-header {
  padding: 25px 0;
  transition: 0.5s;
}

.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-logo {
  position: relative;
  z-index: 200;
  transition: 0.3s;
}
@media (hover: hover) and (pointer: fine) {
  .header-logo:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}
@media screen and (max-width: 599px) {
  .header-logo img {
    width: 280px;
  }
}
@media screen and (max-width: 374px) {
  .header-logo img {
    width: 70vw;
  }
}

.header-nav {
  display: flex;
  gap: 56px;
  font-family: "Gantari", sans-serif;
  font-weight: 700;
}
@media screen and (max-width: 1023px) {
  .header-nav {
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 48px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    background-color: #F0EBD8;
  }
}
.header-nav.active {
  opacity: 1;
  visibility: visible;
  transition: 0.3s;
}

.header-nav-list {
  display: flex;
  align-items: center;
  gap: 40px;
  font-size: 18px;
  letter-spacing: 0.02em;
}
@media screen and (max-width: 1023px) {
  .header-nav-list {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
}
.header-nav-list li {
  position: relative;
}
.header-nav-list li::after {
  content: "";
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: -10px;
  left: 50%;
  width: 6px;
  height: 6px;
  transform: translateX(-50%) rotate(45deg);
  background-color: #FFBE41;
  transition: 0.3s;
}
@media screen and (max-width: 1023px) {
  .header-nav-list li::after {
    left: -16px;
    bottom: 50%;
    transform: translateY(50%) rotate(45deg);
  }
}
.header-nav-list li.active::after {
  opacity: 1;
  visibility: visible;
}
@media (hover: hover) and (pointer: fine) {
  .header-nav-list li:hover::after {
    opacity: 1;
    visibility: visible;
    transition: 0.3s;
  }
}

.header-menu-btn {
  display: none;
}
@media screen and (max-width: 1023px) {
  .header-menu-btn {
    display: block;
    position: fixed;
    bottom: 12px;
    right: 12px;
    z-index: 200;
    width: 56px;
    height: 56px;
    box-shadow: 0 0 40px 0 color-mix(in srgb, #333129 8%, transparent);
    border-radius: 50%;
    background-color: #fff;
    transition: 0.9s;
    cursor: pointer;
  }
}

.header-menu-btn-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 16px;
}
.header-menu-btn-inner span {
  position: absolute;
  left: 0;
  height: 2px;
  background-color: #5C5849;
  transition: 0.3s;
}
.header-menu-btn-inner span:nth-child(1) {
  top: 0;
  width: 100%;
}
.header-menu-btn-inner span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
}
.header-menu-btn-inner span:nth-child(3) {
  bottom: 0;
  width: 100%;
}
.header-menu-btn-inner.active span {
  position: absolute;
  left: 0;
  height: 2px;
  background-color: #5C5849;
  transition: 0.3s;
}
.header-menu-btn-inner.active span:nth-child(1) {
  top: 0;
  width: 100%;
  transform: translateY(7px) rotate(45deg);
}
.header-menu-btn-inner.active span:nth-child(2) {
  opacity: 0;
}
.header-menu-btn-inner.active span:nth-child(3) {
  bottom: 0;
  width: 100%;
  transform: translateY(-7px) rotate(-45deg);
}

.footer {
  margin: 160px 0 60px;
}
@media screen and (max-width: 599px) {
  .footer {
    margin: 80px 0 48px;
  }
}

.footer-inner {
  display: flex;
  justify-content: space-between;
  gap: 36px;
  font-family: "Gantari", sans-serif;
  letter-spacing: 0.02em;
}
@media screen and (max-width: 1023px) {
  .footer-inner {
    flex-direction: column;
  }
}

@media screen and (max-width: 599px) {
  .footer-logo {
    width: 228px;
  }
}

.footer-info {
  margin-top: 30px;
  font-size: 14px;
}

.footer-nav {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  font-weight: 700;
  font-size: 16px;
}
@media screen and (max-width: 1023px) {
  .footer-nav {
    justify-content: flex-start;
  }
}
.footer-nav a {
  transition: 0.3s;
}
@media (hover: hover) and (pointer: fine) {
  .footer-nav a:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}

.footer-copyright {
  display: block;
  margin-top: 60px;
  font-size: 14px;
}
@media screen and (max-width: 1023px) {
  .footer-copyright {
    margin-top: 32px;
  }
}
.footer-copyright span {
  display: inline-block;
}

#splash {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #F0EBD8;
  text-align: center;
}

/* Loading画像中央配置　*/
#splash_logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Loading アイコンの大きさ設定　*/
#splash_logo img {
  width: 260px;
}

.kv-text {
  margin: 140px 0 160px;
  color: #5C5849;
  font-family: "Shippori Mincho", serif;
  font-weight: 400;
  font-size: 40px;
}
@media screen and (max-width: 599px) {
  .kv-text {
    margin: 40px 0 80px;
    font-size: 26px;
  }
}
@media screen and (max-width: 424px) {
  .kv-text {
    font-size: 6.1320754717vw;
  }
}

.kv-image {
  display: block;
  overflow: hidden;
  margin-bottom: 80px;
}
@media screen and (max-width: 599px) {
  .kv-image {
    margin-bottom: 60px;
  }
}

.kv-image-item {
  opacity: 0;
}
.kv-image-item.pc-only {
  display: block;
  aspect-ratio: 1440/528;
}
@media screen and (max-width: 767px) {
  .kv-image-item.pc-only {
    display: none;
  }
}
.kv-image-item.sp-only {
  display: none;
}
@media screen and (max-width: 767px) {
  .kv-image-item.sp-only {
    display: block;
    aspect-ratio: 390/332;
  }
}

.top-section-title {
  margin-bottom: 28px;
  font-family: "Noto Serif Display", serif;
  font-weight: 400;
  font-size: 56px;
  line-height: 1;
}
@media screen and (max-width: 599px) {
  .top-section-title {
    font-size: 44px;
  }
}

.top-about {
  margin-top: 130px;
}
@media screen and (max-width: 599px) {
  .top-about {
    margin-top: 90px;
  }
}

.top-about-inner {
  display: flex;
  align-items: flex-end;
  gap: 70px 52px;
}
@media screen and (max-width: 1023px) {
  .top-about-inner {
    flex-direction: column-reverse;
    align-items: center;
  }
}
@media screen and (max-width: 599px) {
  .top-about-inner {
    gap: 48px;
  }
}

.top-about-text {
  margin-left: 50px;
}
@media screen and (max-width: 1023px) {
  .top-about-text {
    margin-left: 0;
    text-align: center;
  }
}
@media screen and (max-width: 424px) {
  .top-about-text .lg {
    text-align: left;
  }
}

.top-about-image {
  display: flex;
  gap: 12px;
  width: 592px;
}
@media screen and (max-width: 1200px) {
  .top-about-image {
    width: 500px;
  }
}
@media screen and (max-width: 1023px) {
  .top-about-image {
    justify-content: center;
    margin: 0 auto;
  }
}
@media screen and (max-width: 767px) {
  .top-about-image {
    gap: 8px;
    width: auto;
    max-width: 500px;
  }
}
.top-about-image img {
  opacity: 0;
  flex-shrink: 0;
  width: calc((100% - 12px) / 2);
  border-radius: 6px;
}
@media screen and (max-width: 767px) {
  .top-about-image img {
    width: calc((100% - 8px) / 2);
  }
}
.top-about-image.anime img {
  opacity: 1;
  transition: all 1.2s cubic-bezier(0.33, 1, 0.68, 1);
}
.top-about-image.anime img:first-child {
  transform: translateY(30px);
}
@media screen and (max-width: 599px) {
  .top-about-image.anime img:first-child {
    transform: translateY(3vw);
  }
}
.top-about-image.anime img:last-child {
  transform: translateY(-30px);
}
@media screen and (max-width: 599px) {
  .top-about-image.anime img:last-child {
    transform: translateY(-3vw);
  }
}

.top-service {
  margin-top: 160px;
}
@media screen and (max-width: 599px) {
  .top-service {
    margin-top: 72px;
  }
}

.top-service-inner {
  display: grid;
  gap: 0 52px;
}
@media screen and (max-width: 1023px) {
  .top-service-inner {
    display: block;
  }
}

.top-service-text {
  grid-row: 1/2;
  grid-column: 2/3;
}
@media screen and (max-width: 1023px) {
  .top-service-text {
    text-align: center;
  }
}
@media screen and (max-width: 424px) {
  .top-service-text .lg {
    text-align: left;
  }
}

.top-service-list {
  grid-row: 1/3;
  grid-column: 1/2;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 450px;
}
@media screen and (max-width: 1023px) {
  .top-service-list {
    margin: 32px auto 0;
  }
}
@media screen and (max-width: 599px) {
  .top-service-list {
    width: auto;
    gap: 8px;
  }
}
.top-service-list.anime .top-service-item {
  opacity: 1;
  transition: all 1.2s cubic-bezier(0.33, 1, 0.68, 1);
}
.top-service-list.anime .top-service-item:first-child {
  transform: translateX(-32px);
}
.top-service-list.anime .top-service-item:last-child {
  transform: translateX(32px);
}
@media screen and (max-width: 599px) {
  .top-service-list.anime .top-service-item:last-child {
    transition-delay: 0.4s;
  }
}
@media screen and (max-width: 599px) {
  .top-service-list.anime .top-service-item:first-child, .top-service-list.anime .top-service-item:last-child {
    transform: translateX(0) translateY(0);
  }
}

.top-service-item {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 386px;
  border-radius: 8px;
  padding: 28px 36px 28px 32px;
  margin: 0 auto;
  opacity: 0;
  transition: all 0.9s cubic-bezier(0.33, 1, 0.68, 1);
  background-color: #fff;
}
@media screen and (max-width: 1023px) {
  .top-service-item {
    width: 100%;
    max-width: 386px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 599px) {
  .top-service-item {
    padding: 16px;
    transform: translateY(20px);
  }
}
@media (hover: hover) and (pointer: fine) {
  .top-service-item:hover {
    transition: 0.3s;
    background-color: #F5F3EB;
  }
}

@media screen and (max-width: 599px) {
  .top-service-image {
    width: 70px;
  }
}

.top-service-item-title {
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.04em;
}
@media screen and (max-width: 599px) {
  .top-service-item-title {
    font-size: 16px;
  }
}

.top-service-item-detail {
  display: flex;
  flex-wrap: wrap;
  gap: 2px 12px;
  margin-top: 8px;
  color: #9A9376;
  font-weight: 500;
  font-size: 15px;
}
@media screen and (max-width: 599px) {
  .top-service-item-detail {
    font-size: 13px;
  }
}

.lower-top {
  margin: 56px 0 100px;
}
@media screen and (max-width: 599px) {
  .lower-top {
    margin: 36px 0 48px;
  }
}

.page-title {
  margin-bottom: 64px;
}
@media screen and (max-width: 599px) {
  .page-title {
    margin-bottom: 24px;
  }
}
.page-title span {
  display: block;
}
.page-title .en {
  font-family: "Noto Serif Display", serif;
  font-weight: 400;
  font-size: 60px;
}
@media screen and (max-width: 599px) {
  .page-title .en {
    font-size: 40px;
  }
}
.page-title .jp {
  margin-top: 4px;
  font-family: "Shippori Mincho", serif;
  font-weight: 400;
  font-size: 32px;
}
@media screen and (max-width: 599px) {
  .page-title .jp {
    font-size: 24px;
  }
}

.service {
  max-width: 1220px;
  margin: 0 auto 64px;
  padding: 52px 0 64px;
  border-radius: 36px;
  background-color: #fff;
}
@media screen and (max-width: 599px) {
  .service {
    margin: 0 auto 48px;
    padding: 36px 0 48px;
  }
}

.service-title01 {
  padding-bottom: 40px;
}
@media screen and (max-width: 599px) {
  .service-title01 {
    padding-bottom: 32px;
  }
}

.service-title02 {
  padding-bottom: 24px;
}
@media screen and (max-width: 599px) {
  .service-title02 {
    padding-bottom: 16px;
  }
}

.service-content {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding-top: 44px;
  border-top: 1px solid #E5E3DC;
}
@media screen and (max-width: 767px) {
  .service-content {
    flex-direction: column;
    gap: 36px;
    padding-top: 32px;
  }
}
.service-content:nth-child(n+2) {
  margin-top: 40px;
}

.service-content-title {
  margin-bottom: 24px;
}
@media screen and (max-width: 599px) {
  .service-content-title {
    margin-bottom: 16px;
  }
}

.service-content-left,
.service-content-right {
  width: calc((100% - 40px) / 2);
}
@media screen and (max-width: 767px) {
  .service-content-left,
.service-content-right {
    width: 100%;
  }
}

.service-group:nth-child(n+2) {
  margin-top: 40px;
}
.service-group.vertical {
  display: flex;
  align-items: baseline;
  gap: 20px 36px;
}
@media screen and (max-width: 599px) {
  .service-group.vertical {
    flex-direction: column;
  }
}
.service-group.vertical .service-group-title {
  margin-bottom: 0;
}

.service-group-title {
  flex-shrink: 0;
  margin-bottom: 20px;
}

.value {
  padding: 56px 0 64px;
  border-radius: 36px;
  background-color: #E6E2CF;
}
@media screen and (max-width: 599px) {
  .value {
    padding: 32px 0;
  }
}

.value-section-title {
  margin-bottom: 24px;
  font-family: "Noto Serif Display", serif;
  font-weight: 400;
  font-size: 48px;
}
@media screen and (max-width: 599px) {
  .value-section-title {
    font-size: 32px;
  }
}

.value-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 48px;
}
@media screen and (max-width: 599px) {
  .value-list {
    margin-top: 36px;
  }
}

.value-item {
  display: flex;
  gap: 20px;
  padding: 40px;
  border-radius: 28px;
  background-color: #fff;
}
@media screen and (max-width: 1023px) {
  .value-item {
    flex-direction: column;
  }
}
@media screen and (max-width: 599px) {
  .value-item {
    padding: 28px 20px 36px;
  }
}

.value-main {
  flex-shrink: 0;
  align-self: flex-start;
  position: relative;
  width: 510px;
  font-family: "Noto Serif Display", serif;
  font-weight: 400;
}
@media screen and (max-width: 1023px) {
  .value-main {
    position: static;
    width: 100%;
  }
}
.value-main::after {
  content: "";
  position: absolute;
  top: 24px;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: #E5E3DC;
}
@media screen and (max-width: 1023px) {
  .value-main::after {
    content: none;
  }
}

.value-main-inner {
  display: flex;
  align-items: center;
  gap: 4px 16px;
  position: absolute;
  z-index: 10;
  padding-right: 24px;
  background-color: #fff;
}
@media screen and (max-width: 1023px) {
  .value-main-inner {
    position: static;
    background-color: transparent;
  }
}
@media screen and (max-width: 599px) {
  .value-main-inner {
    flex-direction: column;
    align-items: center;
    padding-right: 0;
  }
}

.value-number {
  color: #FFBE41;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 0.08em;
}

.value-title {
  font-size: 32px;
}
@media screen and (max-width: 599px) {
  .value-title {
    font-size: 28px;
  }
}

.greeting {
  margin-top: 90px;
}
@media screen and (max-width: 599px) {
  .greeting {
    margin-top: 48px;
  }
}
@media screen and (max-width: 1023px) {
  .greeting .section-title {
    text-align: center;
  }
}

.greeting-content {
  display: flex;
  gap: 32px 60px;
  margin-top: 48px;
}
@media screen and (max-width: 1023px) {
  .greeting-content {
    flex-direction: column-reverse;
    align-items: center;
  }
}
@media screen and (max-width: 599px) {
  .greeting-content {
    margin-top: 32px;
  }
}

.greeting-group {
  margin-bottom: -20px;
}
.greeting-group p {
  margin-bottom: 20px;
}
.greeting-group:nth-child(n+2) {
  margin-top: 68px;
  border-top: 1px solid #CCC7B4;
  padding-top: 48px;
}
@media screen and (max-width: 599px) {
  .greeting-group:nth-child(n+2) {
    margin-top: 52px;
    padding-top: 32px;
  }
}

.greeting-name-wrap {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 32px;
}
@media screen and (max-width: 599px) {
  .greeting-name-wrap {
    margin-bottom: 24px;
  }
}

.greeting-name {
  font-family: "Shippori Mincho", serif;
  font-weight: 400;
  font-size: 20px;
}

.greeting-position {
  color: #9A9376;
}

.greeting-image {
  flex-shrink: 0;
}
@media screen and (max-width: 599px) {
  .greeting-image {
    max-width: 270px;
  }
}
.greeting-image img {
  border-radius: 28px;
}

.company {
  max-width: 920px;
  margin: 100px auto 0;
  padding: 48px 64px;
  border-radius: 28px;
  background-color: #fff;
}
@media screen and (max-width: 599px) {
  .company {
    margin: 48px auto 0;
    padding: 36px 20px;
  }
}

.company-table {
  width: 100%;
  margin-top: 40px;
  font-size: 18px;
}
.company-table tr:nth-child(n+2) {
  border-top: 1px solid #E5E3DC;
}
.company-table tr:nth-child(n+2) th {
  padding-top: 32px;
}
@media screen and (max-width: 599px) {
  .company-table tr:nth-child(n+2) th {
    padding-top: 22px;
  }
}
.company-table tr:nth-child(n+2) td {
  padding-top: 32px;
}
@media screen and (max-width: 599px) {
  .company-table tr:nth-child(n+2) td {
    padding-top: 6px;
  }
}
.company-table tr:not(:last-child) th {
  padding-bottom: 32px;
}
@media screen and (max-width: 599px) {
  .company-table tr:not(:last-child) th {
    padding-bottom: 0;
  }
}
.company-table tr:not(:last-child) td {
  padding-bottom: 32px;
}
@media screen and (max-width: 599px) {
  .company-table tr:not(:last-child) td {
    padding-top: 10px;
    padding-bottom: 22px;
  }
}
@media screen and (max-width: 599px) {
  .company-table th, .company-table td {
    display: block;
  }
}
.company-table th {
  width: 220px;
  padding-right: 40px;
  padding-left: 12px;
  vertical-align: top;
}
@media screen and (max-width: 599px) {
  .company-table th {
    padding-right: 0;
    padding-left: 0;
  }
}
@media screen and (max-width: 599px) {
  .company-table td {
    padding-left: 24px;
    font-size: 16px;
  }
}

.img-isms {
  margin-top: 20px;
}

.history {
  margin-top: 100px;
}
@media screen and (max-width: 599px) {
  .history {
    margin-top: 48px;
  }
}

.history-table {
  margin-top: 40px;
  justify-content: space-between;
  gap: 60px;
}
@media screen and (max-width: 599px) {
  .history-table {
    margin-top: 32px;
  }
}
.history-table.pc {
  display: flex;
}
@media screen and (max-width: 767px) {
  .history-table.pc {
    display: none;
  }
}
.history-table.sp {
  display: none;
}
@media screen and (max-width: 767px) {
  .history-table.sp {
    display: flex;
  }
}

.history-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 140px;
  padding: 16px 40px;
  border-radius: 16px;
  background-color: #fff;
}
@media screen and (max-width: 599px) {
  .history-item {
    height: auto;
    min-height: 103px;
    padding: 16px 24px;
  }
}
.history-item + .history-item {
  margin-top: 32px;
}
@media screen and (max-width: 599px) {
  .history-item + .history-item {
    margin-top: 12px;
  }
}
.history-item::before, .history-item::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.history-item::before {
  z-index: -1;
  height: 3px;
  background-size: 18px 3px;
  background-image: linear-gradient(to right, #9A9376 9px, transparent 9px);
}
.history-item::after {
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border: 4px solid #FFBE41;
  border-radius: 50%;
}
.history-item .date {
  color: #9A9376;
  font-family: "Shippori Mincho", serif;
  font-weight: 400;
  font-size: 20px;
}
@media screen and (max-width: 599px) {
  .history-item .date {
    font-size: 18px;
  }
}
.history-item .text {
  margin-top: 8px;
  font-size: 18px;
  line-height: 1.75;
}
@media screen and (max-width: 599px) {
  .history-item .text {
    font-size: 16px;
  }
}

.history-left,
.history-right {
  width: calc((100% - 130px) / 2);
}

.history-left {
  margin-bottom: 24px;
}
.history-left .history-item::before {
  right: -70px;
  width: 74px;
}
.history-left .history-item::after {
  right: -75px;
}

.history-right {
  margin-top: 87px;
}
@media screen and (max-width: 767px) {
  .history-right {
    width: calc(100% - 65px);
    margin-top: 0;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 767px) {
  .history-right .history-item:nth-child(2n) {
    margin-left: 20px;
  }
  .history-right .history-item:nth-child(2n)::before {
    left: -79px;
  }
  .history-right .history-item:nth-child(2n)::after {
    left: -95px;
  }
}
.history-right .history-item::before {
  left: -59px;
  width: 80px;
}
.history-right .history-item::after {
  left: -75px;
}

.history-stick {
  width: 10px;
  margin-top: 48px;
  background-color: #DBD4BE;
}
@media screen and (max-width: 599px) {
  .history-stick {
    margin-top: 32px;
  }
}

.recruit-kv-inner {
  margin-top: 100px;
}
@media screen and (max-width: 599px) {
  .recruit-kv-inner {
    margin-top: 48px;
  }
}

.recruit-content {
  margin-top: 40px;
  padding: 56px 80px;
  border-radius: 36px;
  background-color: #fff;
}
@media screen and (max-width: 599px) {
  .recruit-content {
    margin-top: 32px;
    padding: 36px 20px;
  }
}
@media screen and (max-width: 599px) {
  .recruit-content .content-title {
    text-align: center;
  }
}

.recruit-group-wrap {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 40px;
}
@media screen and (max-width: 599px) {
  .recruit-group-wrap {
    gap: 36px;
    margin-top: 32px;
  }
}

.recruit-group .list,
.recruit-group .list {
  margin-top: 12px;
}

.recruit-group-title {
  margin-bottom: 16px;
}

@media screen and (max-width: 599px) {
  .policy {
    margin-top: -24px;
  }
}
@media screen and (max-width: 599px) {
  .policy .md {
    font-size: 15px;
  }
}

.policy-title {
  margin-bottom: 36px;
  font-weight: 700;
  font-size: 28px;
  text-align: center;
}
@media screen and (max-width: 599px) {
  .policy-title {
    margin-bottom: 24px;
    font-size: 20px;
    text-align: left;
  }
}
.policy-title span {
  font-size: 24px;
}
@media screen and (max-width: 599px) {
  .policy-title span {
    font-size: 18px;
  }
}

.policy-info {
  margin-bottom: 36px;
  text-align: right;
}
@media screen and (max-width: 599px) {
  .policy-info {
    margin-bottom: 24px;
    font-size: 14px;
  }
}

.policy-content {
  margin-top: 56px;
}
@media screen and (max-width: 599px) {
  .policy-content {
    margin-top: 36px;
  }
}
.policy-content .list,
.policy-content .number-list {
  margin-top: 16px;
}
.policy-content .list {
  margin-left: 1.2em;
}
.policy-content .number-list {
  margin-left: 2.5em;
}
.policy-content p {
  margin-top: 16px;
}
.policy-content .policy-info {
  margin-top: 36px;
}
@media screen and (max-width: 599px) {
  .policy-content .policy-info {
    margin-top: 24px;
  }
}

.policy-content-title {
  display: flex;
  margin-bottom: 16px;
}
.policy-content-title .number {
  flex-shrink: 0;
}

.policy-group {
  margin-top: 40px;
}
@media screen and (max-width: 599px) {
  .policy-group {
    margin-top: 27px;
  }
}

.policy-group-title {
  margin-bottom: 16px;
  font-size: 20px;
}
@media screen and (max-width: 599px) {
  .policy-group-title {
    font-size: 16px;
  }
}

.policy-list {
  margin-left: 2.6em;
}

.policy-block {
  margin-top: 32px;
}

.policy-block-title {
  margin-bottom: 16px;
  font-weight: 700;
}

.policy-table {
  overflow: auto;
  margin-top: 32px;
}
@media screen and (max-width: 1023px) {
  .policy-table.w1023 {
    width: calc(100vw - 20px);
  }
  .policy-table.w1023 table {
    table-layout: auto;
  }
}
@media screen and (max-width: 767px) {
  .policy-table.w767 {
    width: calc(100vw - 20px);
  }
  .policy-table.w767 table {
    table-layout: auto;
  }
}
@media screen and (max-width: 599px) {
  .policy-table.w599 {
    width: calc(100vw - 20px);
  }
  .policy-table.w599 table {
    table-layout: auto;
  }
}
.policy-table table {
  width: 100%;
  table-layout: fixed;
}
.policy-table table tr:first-child {
  background-color: #DBD4BE;
}
.policy-table table tr:first-child th, .policy-table table tr:first-child td {
  font-weight: 700;
}
.policy-table table tr:nth-child(n+2) {
  background-color: #fff;
  font-size: 15px;
}
.policy-table table tr:nth-child(n+3) {
  border-top: 1px solid #E5E3DC;
}
.policy-table table th, .policy-table table td {
  text-align: center;
  white-space: nowrap;
}
.policy-table table th a, .policy-table table td a {
  color: #2C477A;
  text-decoration: underline;
}
.policy-table table th a:hover, .policy-table table td a:hover {
  text-decoration: none;
}
.policy-table table th {
  padding: 16px 20px;
}
.policy-table table td {
  padding: 18px 20px;
  font-size: 15px;
  line-height: 1.75;
}

.en .btn.more-btn {
  max-width: 300px;
}
.en .kv-text,
.en .section-title,
.en .content-title,
.en .group-title,
.en .policy-title {
  font-family: "Noto Serif Display", serif;
  font-weight: 400;
}
@media screen and (max-width: 1023px) {
  .en .policy-table.w1023 th:last-child .br-last {
    display: none;
  }
}
.en .policy-table.w767 th:last-child .br-last {
  display: none;
}
@media screen and (max-width: 1023px) {
  .en .policy-table.w767 th:last-child .br-last {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .en .policy-table.w767 th:last-child .br-last {
    display: none;
  }
}

.kv-text-item {
  display: block;
  overflow: hidden;
}

.kv-text-item-inner {
  display: block;
  opacity: 0;
}

.lower-kv-lead {
  opacity: 0;
}

.stepIn {
  animation-name: slideTextY100;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

@keyframes slideTextY100 {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.slideIn {
  animation-name: slideTextY20;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
}

@keyframes slideTextY20 {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.fadeInUp {
  opacity: 0;
}

.zoomOut {
  animation-name: zoomOut;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
}

@keyframes zoomOut {
  from {
    transform: scale(1.05);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}