@use "../settings/vars" as v;
@use "../settings/mixins" as m;

// splash
#splash {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: v.$bg;
  text-align: center;
}

/* Loading画像中央配置　*/
#splash_logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Loading アイコンの大きさ設定　*/
#splash_logo img {
  width: 260px;
}

// kv
.kv-text {
  margin: 140px 0 160px;
  color: v.$base-light;
  @include v.mincho;
  font-size: 40px;
  @include m.mq(max599) {
    margin: 40px 0 80px;
    font-size: 26px;
  }
  @include m.mq(max424) {
    font-size: calc((26/424)*100vw);
  }
}
.kv-image {
  display: block;
  overflow: hidden;
  margin-bottom: 80px;
  @include m.mq(max599) {
    margin-bottom: 60px;
  }
}
.kv-image-item {
  opacity: 0;
  &.pc-only {
    display: block;
    aspect-ratio: 1440 / 528;
    @include m.mq(max767) {
      display: none;
    }
  }
  &.sp-only {
    display: none;
    @include m.mq(max767) {
      display: block;
      aspect-ratio: 390 / 332;
    }
  }
}

// TOPのsection title
.top-section-title {
  margin-bottom: 28px;
  @include v.noto;
  font-size: 56px;
  line-height: 1;
  @include m.mq(max599) {
    font-size: 44px;
  }
}

// About us
.top-about {
  margin-top: 130px;
  @include m.mq(max599) {
    margin-top: 90px;
  }
}
.top-about-inner {
  display: flex;
  align-items: flex-end;
  gap: 70px 52px;
  @include m.mq(max1023) {
    flex-direction: column-reverse;
    align-items: center;
  }
  @include m.mq(max599) {
    gap: 48px;
  }
}
.top-about-text {
  margin-left: 50px;
  @include m.mq(max1023) {
    margin-left: 0;
    text-align: center;
  }
  @include m.mq(max424) {
    .lg {
      text-align: left;
    }
  }
}
.top-about-image {
  display: flex;
  gap: 12px;
  width: 592px;
  @include m.mq(max1200) {
    width: 500px;
  }
  @include m.mq(max1023) {
    justify-content: center;
    margin: 0 auto;
  }
  @include m.mq(max767) {
    gap: 8px;
    width: auto;
    max-width: 500px;
  }
  img {
    opacity: 0;
    flex-shrink: 0;
    width: calc((100% - 12px)/2);
    border-radius: 6px;
    @include m.mq(max767) {
      width: calc((100% - 8px)/2);
    }
  }
  &.anime {
    img {
      opacity: 1;
      transition: all 1.2s cubic-bezier(0.33, 1, 0.68, 1);
      &:first-child {
        transform: translateY(30px);
        @include m.mq(max599) {
          transform: translateY(3vw);
        }
      }
      &:last-child {
        transform: translateY(-30px);
        @include m.mq(max599) {
          transform: translateY(-3vw);
        }
      }
    }
  }
}

// Services
.top-service {
  margin-top: 160px;
  @include m.mq(max599) {
    margin-top: 72px;
  }
}
.top-service-inner {
  display: grid;
  gap: 0 52px;
  @include m.mq(max1023) {
    display: block;
  }
}
.top-service-text {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  @include m.mq(max1023) {
    text-align: center;
  }
  @include m.mq(max424) {
    .lg {
      text-align: left;
    }
  }
}
.top-service-list {
  grid-row: 1 / 3;
  grid-column: 1 / 2;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 450px;
  @include m.mq(max1023) {
    margin: 32px auto 0;
  }
  @include m.mq(max599) {
    width: auto;
    gap: 8px;
  }
  &.anime {
    .top-service-item {
      opacity: 1;
      transition: all 1.2s cubic-bezier(0.33, 1, 0.68, 1);
      &:first-child {
        transform: translateX(-32px);
      }
      &:last-child {
        transform: translateX(32px);
        @include m.mq(max599) {
          transition-delay: 0.4s;
        }
      }
      &:first-child,
      &:last-child {
        @include m.mq(max599) {
          transform: translateX(0) translateY(0);
        }
      }
    }
  }
}
.top-service-item {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 386px;
  border-radius: 8px;
  padding: 28px 36px 28px 32px;
  margin: 0 auto;
  opacity: 0;
  transition: all 0.9s cubic-bezier(0.33, 1, 0.68, 1);
  background-color: v.$white;
  @include m.mq(max1023) {
    width: 100%;
    max-width: 386px;
    margin: 0 auto;
  }
  @include m.mq(max599) {
    padding: 16px;
    transform: translateY(20px);
  }
  @include m.hover() {
    transition: 0.3s;
    background-color: v.$bg-light;
  }
}
.top-service-image {
  @include m.mq(max599) {
    width: 70px;
  }
}
.top-service-item-title {
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.04em;
  @include m.mq(max599) {
    font-size: 16px;
  }
}
.top-service-item-detail {
  display: flex;
  flex-wrap: wrap;
  gap: 2px 12px;
  margin-top: 8px;
  color: v.$base-xlight;
  font-weight: 500;
  font-size: 15px;
  @include m.mq(max599) {
    font-size: 13px;
  }
}

