@use "../settings/vars" as v;
@use "../settings/mixins" as m;


// 下層TOP
.lower-top {
  margin: 56px 0 100px;
  @include m.mq(max599) {
    margin: 36px 0 48px;
  }
}
.page-title {
  margin-bottom: 64px;
  @include m.mq(max599) {
    margin-bottom: 24px;
  }
  span {
    display: block;
  }
  .en {
    @include v.noto;
    font-size: 60px;
    @include m.mq(max599) {
      font-size: 40px;
    }
  }
  .jp {
    margin-top: 4px;
    @include v.mincho;
    font-size: 32px;
    @include m.mq(max599) {
      font-size: 24px;
    }
  }
}


// Service
.service {
  max-width: 1220px;
  margin: 0 auto 64px;
  padding: 52px 0 64px;
  border-radius: 36px;
  background-color: v.$white;
  @include m.mq(max599) {
    margin: 0 auto 48px;
    padding: 36px 0 48px;
  }
}
.service-title01 {
  padding-bottom: 40px;
  @include m.mq(max599) {
    padding-bottom: 32px;
  }
}
.service-title02 {
  padding-bottom: 24px;
  @include m.mq(max599) {
    padding-bottom: 16px;
  }
}
.service-content {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding-top: 44px;
  border-top: 1px solid v.$border;
  @include m.mq(max767) {
    flex-direction: column;
    gap: 36px;
    padding-top: 32px;
  }
  &:nth-child(n+2) {
    margin-top: 40px;
  }
}
.service-content-title {
  margin-bottom: 24px;
  @include m.mq(max599) {
    margin-bottom: 16px;
  }
}
.service-content-left,
.service-content-right {
  width: calc((100% - 40px)/2);
  @include m.mq(max767) {
    width: 100%;
  }
}
.service-content-left {
}
.service-content-right {
}
.service-group {
  &:nth-child(n+2) {
    margin-top: 40px;
  }
  &.vertical {
    display: flex;
    align-items: baseline;
    gap: 20px 36px;
    @include m.mq(max599) {
      flex-direction: column;
    }
    .service-group-title {
      margin-bottom: 0;
    }
  }
}
.service-group-title {
  flex-shrink: 0;
  margin-bottom: 20px;
}

// About us
.value {
  padding: 56px 0 64px;
  border-radius: 36px;
  background-color: v.$brand-main;
  @include m.mq(max599) {
    padding: 32px 0;
  }
}
.value-section-title {
  margin-bottom: 24px;
  @include v.noto;
  font-size: 48px;
  @include m.mq(max599) {
    font-size: 32px;
  }
}
.value-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 48px;
  @include m.mq(max599) {
    margin-top: 36px;
  }
}
.value-item {
  display: flex;
  gap: 20px;
  padding: 40px;
  border-radius: 28px;
  background-color: v.$white;
  @include m.mq(max1023) {
    flex-direction: column;
  }
  @include m.mq(max599) {
    padding: 28px 20px 36px;
  }
}
.value-main {
  flex-shrink: 0;
  align-self: flex-start;
  position: relative;
  width: 510px;
  @include v.noto;
  @include m.mq(max1023) {
    position: static;
    width: 100%;
  }
  &::after {
    content: '';
    position: absolute;
    top: 24px;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: v.$border;
    @include m.mq(max1023) {
      content: none;
    }
  }
}
.value-main-inner {
  display: flex;
  align-items: center;
  gap: 4px 16px;
  position: absolute;
  z-index: 10;
  padding-right: 24px;
  background-color: v.$white;
  @include m.mq(max1023) {
    position: static;
    background-color: transparent;
  }
  @include m.mq(max599) {
    flex-direction: column;
    align-items: center;
    padding-right: 0;
  }
}
.value-number {
  color: v.$primary;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 0.08em;
}
.value-title {
  font-size: 32px;
  @include m.mq(max599) {
    font-size: 28px;
  }
}
.greeting {
  margin-top: 90px;
  @include m.mq(max599) {
    margin-top: 48px;
  }
  .section-title {
    @include m.mq(max1023) {
      text-align: center;
    }
  }
}
.greeting-content {
  display: flex;
  gap: 32px 60px;
  margin-top: 48px;
  @include m.mq(max1023) {
    flex-direction: column-reverse;
    align-items: center;
  }
  @include m.mq(max599) {
    margin-top: 32px;
  }
}
.greeting-group {
  margin-bottom: -20px;
  p {
    margin-bottom: 20px;
  }
  &:nth-child(n+2) {
    margin-top: 68px;
    border-top: 1px solid v.$border-dark;
    padding-top: 48px;
    @include m.mq(max599) {
      margin-top: 52px;
      padding-top: 32px;
    }
  }
}
.greeting-name-wrap {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 32px;
  @include m.mq(max599) {
    margin-bottom: 24px;
  }
}
.greeting-name {
  @include v.mincho;
  font-size: 20px;
}
.greeting-position {
  color: v.$base-xlight;
}
.greeting-image {
  flex-shrink: 0;
  @include m.mq(max599) {
    max-width: 270px;
  }
  img {
    border-radius: 28px;
  }
}
.company {
  max-width: 920px;
  margin: 100px auto 0;
  padding: 48px 64px;
  border-radius: 28px;
  background-color: v.$white;
  @include m.mq(max599) {
    margin: 48px auto 0;
    padding: 36px 20px;
  }
}
.company-table {
  width: 100%;
  margin-top: 40px;
  font-size: 18px;
  tr {
    &:nth-child(n+2) {
      border-top: 1px solid v.$border;
      th {
        padding-top: 32px;
        @include m.mq(max599) {
          padding-top: 22px;
        }
      }
      td {
        padding-top: 32px;
        @include m.mq(max599) {
          padding-top: 6px;
        }
      }
    }
    &:not(:last-child) {
      th {
        padding-bottom: 32px;
        @include m.mq(max599) {
          padding-bottom: 0;
        }
      }
      td {
        padding-bottom: 32px;
        @include m.mq(max599) {
          padding-top: 10px;
          padding-bottom: 22px;
        }
      }
    }
  }
  th,td {
    @include m.mq(max599) {
      display: block;
    }
  }
  th {
    width: 220px;
    padding-right: 40px;
    padding-left: 12px;
    vertical-align: top;
    @include m.mq(max599) {
      padding-right: 0;
      padding-left: 0;
    }
  }
  td {
    @include m.mq(max599) {
      padding-left: 24px;
      font-size: 16px;
    }
  }
}
.img-isms {
  margin-top: 20px;
}
.history {
  margin-top: 100px;
  @include m.mq(max599) {
    margin-top: 48px;
  }
}
.history-table {
  margin-top: 40px;
  justify-content: space-between;
  gap: 60px;
  @include m.mq(max599) {
    margin-top: 32px;
  }
  &.pc {
    display: flex;
    @include m.mq(max767) {
      display: none;
    }
  }
  &.sp {
    display: none;
    @include m.mq(max767) {
      display: flex;
    }
  }
}
.history-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 140px;
  padding: 16px 40px;
  border-radius: 16px;
  background-color: v.$white;
  @include m.mq(max599) {
    height: auto;
    min-height: 103px;
    padding: 16px 24px;
  }
  + .history-item {
    margin-top: 32px;
    @include m.mq(max599) {
      margin-top: 12px;
    }
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  &::before {
    z-index: -1;
    height: 3px;
    background-size: 18px 3px;
    background-image: linear-gradient(to right, v.$base-xlight 9px, transparent 9px);
  }
  &::after {
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border: 4px solid v.$primary;
    border-radius: 50%;
  }
  .date {
    color: v.$base-xlight;
    @include v.mincho;
    font-size: 20px;
    @include m.mq(max599) {
      font-size: 18px;
    }
  }
  .text {
    margin-top: 8px;
    font-size: 18px;
    line-height: 1.75;
    @include m.mq(max599) {
      font-size: 16px;
    }
  }
}
.history-left,
.history-right {
  width: calc((100% - 130px)/2);
}
.history-left {
  margin-bottom: 24px;
  .history-item {
    &::before {
      right: -70px;
      width: 74px;
    }
    &::after {
      right: -75px;
    }
  }
}
.history-right {
  margin-top: 87px;
  @include m.mq(max767) {
    width: calc(100% - 65px);
    margin-top: 0;
    margin-bottom: 20px;
  }
  .history-item {
    &:nth-child(2n) {
      @include m.mq(max767) {
        margin-left: 20px;
        &::before {
          left: -79px;
        }
        &::after {
          left: -95px;
        }
      }
    }
    &::before {
      left: -59px;
      width: 80px;
    }
    &::after {
      left: -75px;
    }
  }
}
.history-stick {
  width: 10px;
  margin-top: 48px;
  background-color: v.$base-xxlight;
  @include m.mq(max599) {
    margin-top: 32px;
  }
}

// Recruiting
.recruit-kv-inner {
  margin-top: 100px;
  @include m.mq(max599) {
    margin-top: 48px;
  }
}
.recruit-content {
  margin-top: 40px;
  padding: 56px 80px;
  border-radius: 36px;
  background-color: v.$white;
  @include m.mq(max599) {
    margin-top: 32px;
    padding: 36px 20px;
  }
  .content-title {
    @include m.mq(max599) {
      text-align: center;
    }
  }
}
.recruit-group-wrap {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 40px;
  @include m.mq(max599) {
    gap: 36px;
    margin-top: 32px;
  }
}
.recruit-group {
  .list,
  .list {
    margin-top: 12px;
  }
}
.recruit-group-title {
  margin-bottom: 16px;
}

// Policy
.policy {
  @include m.mq(max599) {
    margin-top: -24px;
  }
  .md {
    @include m.mq(max599) {
      font-size: 15px;
    }
  }
}
.policy-title {
  margin-bottom: 36px;
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  @include m.mq(max599) {
    margin-bottom: 24px;
    font-size: 20px;
    text-align: left;
  }
  span {
    font-size: 24px;
    @include m.mq(max599) {
      font-size: 18px;
    }
  }
}
.policy-info {
  margin-bottom: 36px;
  text-align: right;
  @include m.mq(max599) {
    margin-bottom: 24px;
    font-size: 14px;
  }
}
.policy-content {
  margin-top: 56px;
  @include m.mq(max599) {
    margin-top: 36px;
  }
  .list,
  .number-list {
    margin-top: 16px;
  }
  .list {
    margin-left: 1.2em;
  }
  .number-list {
    margin-left: 2.5em;
  }
  p {
    margin-top: 16px;
  }
  .policy-info {
    margin-top: 36px;
    @include m.mq(max599) {
      margin-top: 24px;
    }
  }
}
.policy-content-title {
  display: flex;
  margin-bottom: 16px;
  .number {
    flex-shrink: 0;
  }
}
.policy-group {
  margin-top: 40px;
  @include m.mq(max599) {
    margin-top: 27px;
  }
}
.policy-group-title {
  margin-bottom: 16px;
  font-size: 20px;
  @include m.mq(max599) {
    font-size: 16px;
  }
}
.policy-list {
  margin-left: 2.6em;
}
.policy-block {
  margin-top: 32px;
}
.policy-block-title {
  margin-bottom: 16px;
  font-weight: 700;
}
.policy-table {
  overflow: auto;
  margin-top: 32px;
  &.w1023 {
    @include m.mq(max1023) {
      width: calc(100vw - 20px);
      table {
        table-layout: auto;
      }
    }
  }
  &.w767 {
    @include m.mq(max767) {
      width: calc(100vw - 20px);
      table {
        table-layout: auto;
      }
    }
  }
  &.w599 {
    @include m.mq(max599) {
      width: calc(100vw - 20px);
      table {
        table-layout: auto;
      }
    }
  }
  table {
    width: 100%;
    table-layout: fixed;
    tr {
      &:first-child {
        background-color: v.$base-xxlight;
        th,td {
          font-weight: 700;
        }
      }
      &:nth-child(n+2) {
        background-color: v.$white;
        font-size: 15px;
      }
      &:nth-child(n+3) {
        border-top: 1px solid v.$border;
      }
    }
    th,td {
      text-align: center;
      white-space: nowrap;
      a {
        color: v.$secondary;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    th {
      padding: 16px 20px;
    }
    td {
      padding: 18px 20px;
      font-size: 15px;
      line-height: 1.75;
    }
  }
}

